<template>
  <v-container fluid class="login-bkg height-100">
    <v-overlay v-model="loading">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-overlay>
    <v-row :class="$vuetify.breakpoint.xs ? 'pt-75' : 'pt-150'">
      <v-spacer></v-spacer>
      <v-col cols="12" sm="10" md="7" lg="5" xl="4">
        <v-card tile>
          <v-system-bar
            height="60"
            color="primary"
            class="white--text elevation-5"
          >
            <v-card-title>
              <h3>Forgot Password</h3>
            </v-card-title>
          </v-system-bar>
          <v-system-bar height="5" color="accent" class=""></v-system-bar>
          <v-card-text>
            <span class="text-body-1 text--primary">
              Set your new password
            </span>
          </v-card-text>
          <v-divider />
          <v-card-text>
            <div class="pa-4">
              <v-form ref="form" v-model="valid" @submit.prevent="changePwd()">
                <v-text-field
                  v-model="password"
                  label="New Password"
                  dense
                  class="pb-1"
                  :rules="pwdRules"
                  :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPwd ? 'text' : 'password'"
                  @click:append="showPwd = !showPwd"
                ></v-text-field>
                <div class="d-flex justify-center" v-if="errorText !== ''">
                  <p class="error--text text-body-1 font-weight-medium">
                    {{ errorText }}
                  </p>
                </div>
                <div class="d-flex justify-center align-center pt-4">
                  <v-btn
                    type="submit"
                    :disabled="loading"
                    color="primary"
                    class="px-4"
                  >
                    Change Password
                  </v-btn>
                </div>
              </v-form>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import * as login from "../../api/LoginApi";
import * as disp from "../../utility/display";
import * as query from "../../utility/query";

// @ is an alias to /src
export default {
  name: "ForgotPasswordAction",
  data: () => ({
    loading: false,
    message: "Sending Forgot Password Email...",
    valid: true,
    password: "",
    showPwd: false,
    userId: "",
    nonce: "",
    errorText: "",
    pwdRules: [
      (v) => !!v || "Required.",
      (v) => v.length >= 12 || "Min 12 characters",
      (v) =>
        /.*[A-Z].*/.test(v) || "Must contain at least one uppercase letter",
      (v) =>
        /.*[a-z].*/.test(v) || "Must contain at least one lowercase letter",
      (v) => /.*[0-9].*/.test(v) || "Must contain at least one number",
      (v) =>
        /.*[!@#$%^&*()\-_+=].*/.test(v) ||
        "Must contain at least one special character",
    ],
  }),
  mounted() {
    this.nonce = query.GetParameterByName("nonce");
    this.userId = query.GetParameterByName("userId");
  },
  methods: {
    ...mapActions("auth", ["logUserIn"]),
    hardNavTo(href) {
      window.location.href = href;
    },
    navTo(href) {
      this.$router.push({ path: href });
    },
    async checkLoggedIn() {
      try {
        let isLoggedIn = await this.logUserIn();
        if (isLoggedIn) {
          this.loading = false;
          this.navTo("/Resume");
        }
      } finally {
        this.loading = false;
      }
    },
    async changePwd() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      this.loading = true;
      try {
        if (
          disp.IsNullorWhitespace(this.nonce) ||
          disp.IsNullorWhitespace(this.userId) ||
          disp.IsNullorWhitespace(this.password)
        ) {
          this.errorText =
            "Missing a parameter. Please follow the email link again.";
          return;
        }

        const changeResult = await login.ChangePasswordNonce(
          this.nonce,
          this.userId,
          this.password
        );
        if (!changeResult.success) {
          this.errorText = changeResult.displayText;
        } else {
          this.checkLoggedIn();
        }
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.pt-150 {
  padding-top: 150px;
}

.pt-75 {
  padding-top: 75px;
}
</style>
